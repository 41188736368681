/* eslint-disable */
import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { fetchConfigurator } from '../fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AddConfigToBasketResult = Basket | ConfigNotFoundError;

export type Attribute = {
  __typename?: 'Attribute';
  type: Scalars['String'];
  value: Scalars['String'];
};

export type Basket = {
  __typename?: 'Basket';
  basketLines: Array<BasketLine>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BasketLine = {
  __typename?: 'BasketLine';
  /** Basket */
  basket: Basket;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  /** Amount of variants */
  quantity: Scalars['Int'];
  /** System Config */
  systemConfig: SystemConfig;
  /** Total cost of the basket line */
  totalCost: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  /** User who created the basket line */
  userId: Scalars['String'];
};

export type BasketLineNotFoundError = {
  __typename?: 'BasketLineNotFoundError';
  message: Scalars['String'];
};

export type BasketNotFoundError = {
  __typename?: 'BasketNotFoundError';
  message: Scalars['String'];
};

export type CalculationError = {
  __typename?: 'CalculationError';
  configurationSourceKey: Scalars['String'];
  filter?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ClearBasketMutationResult = Basket | BasketNotFoundError;

export type ConfigNotFoundError = {
  __typename?: 'ConfigNotFoundError';
  message: Scalars['String'];
};

export type ConfigVariantLine = {
  __typename?: 'ConfigVariantLine';
  /** Key, signaling from which service this was inserted */
  configurationSourceKey: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  /** Amount of variants */
  quantity: Scalars['Int'];
  /** System Config */
  systemConfig: SystemConfig;
  updatedAt: Scalars['DateTime'];
  /** Product Variant */
  variant: Variant;
};

export type ContactPerson = {
  __typename?: 'ContactPerson';
  email?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
};

export type CreateConfigVariantLineInput = {
  /** Key, representing the source of a grouping of products, EG: Zubehör */
  configurationSourceKey: Scalars['String'];
  /** Amount of variants */
  quantity: Scalars['Int'];
  /** System Config id */
  systemConfigId: Scalars['String'];
  /** Product Variant id */
  variantId: Scalars['String'];
};

export type CreateConfigVariantLineResult = ConfigVariantLine | VariantNotFoundError;

export type CreateSystemConfigResult = SystemConfig | SystemNotFoundError;

export type Customer = {
  __typename?: 'Customer';
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  title: Scalars['String'];
};

export type CustomerSpecificPrice = {
  __typename?: 'CustomerSpecificPrice';
  basePrice: Scalars['Int'];
  price: Scalars['Int'];
};

export type DeleteSystemConfigResult = ConfigNotFoundError | SystemConfig;

export type ExpertCheckMessage = {
  __typename?: 'ExpertCheckMessage';
  authorCustomerId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  message: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum ExpertCheckStatus {
  Acknowledged = 'ACKNOWLEDGED',
  Finished = 'FINISHED',
  NotRequested = 'NOT_REQUESTED',
  Requested = 'REQUESTED'
}

export type FieldHideCondition = {
  __typename?: 'FieldHideCondition';
  createdAt: Scalars['DateTime'];
  /** Disable if Hidden */
  disableIfHidden?: Maybe<Scalars['Boolean']>;
  /** Path to the module and field (moduleName.fieldName) */
  field: Scalars['String'];
  id: Scalars['String'];
  /** Operator */
  operator: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** Value */
  value: Scalars['String'];
};

export type Image = {
  __typename?: 'Image';
  fileName?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['String']>;
  frontendFullPath?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['Int']>;
};

export type InputModuleFieldValue = {
  fieldId: Scalars['String'];
  value: Scalars['String'];
};

export type ModuleFieldValue = {
  __typename?: 'ModuleFieldValue';
  /** System Module Field code */
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  /** System Module Field id */
  fieldId: Scalars['String'];
  id: Scalars['String'];
  /** System Module code */
  moduleCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** Value of the field */
  value: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addConfigToBasket: AddConfigToBasketResult;
  clearBasket: ClearBasketMutationResult;
  createConfigVariantLine: CreateConfigVariantLineResult;
  createSystemConfig: CreateSystemConfigResult;
  deleteSystemConfig: DeleteSystemConfigResult;
  finishExpertCheck: UpdateSystemConfigResult;
  removeBasketLine: RemoveBasketLineResult;
  removeConfigVariantLine: RemoveConfigVariantLineResult;
  requestExpertCheck: UpdateSystemConfigResult;
  sendBasketToOrder: SendBasketToOrderMutationResult;
  updateBasketLine: BasketLine;
  updateConfigVariantLine: UpdateConfigVariantLineResult;
  updateSystemConfig: UpdateSystemConfigResult;
};


export type MutationAddConfigToBasketArgs = {
  configId: Scalars['String'];
};


export type MutationCreateConfigVariantLineArgs = {
  createConfigVariantLineInput: CreateConfigVariantLineInput;
};


export type MutationCreateSystemConfigArgs = {
  systemId: Scalars['String'];
};


export type MutationDeleteSystemConfigArgs = {
  systemConfigId: Scalars['String'];
};


export type MutationFinishExpertCheckArgs = {
  message: Scalars['String'];
  systemConfigId: Scalars['String'];
};


export type MutationRemoveBasketLineArgs = {
  id: Scalars['String'];
};


export type MutationRemoveConfigVariantLineArgs = {
  id: Scalars['String'];
};


export type MutationRequestExpertCheckArgs = {
  message: Scalars['String'];
  systemConfigId: Scalars['String'];
};


export type MutationSendBasketToOrderArgs = {
  id: Scalars['String'];
};


export type MutationUpdateBasketLineArgs = {
  updateBasketLineInput: UpdateBasketLineInput;
};


export type MutationUpdateConfigVariantLineArgs = {
  updateConfigVariantLineInput: UpdateConfigVariantLineInput;
};


export type MutationUpdateSystemConfigArgs = {
  config: UpdateSystemConfigInput;
};

export type Query = {
  __typename?: 'Query';
  accessories: Array<Variant>;
  activeBasket?: Maybe<Basket>;
  activeCustomer?: Maybe<Customer>;
  basket?: Maybe<Basket>;
  baskets?: Maybe<Array<Basket>>;
  contactPerson?: Maybe<ContactPerson>;
  requestedExpertChecks: Array<SystemConfig>;
  system: System;
  systemConfig?: Maybe<SystemConfig>;
  systemConfigs: Array<SystemConfig>;
  systems: Array<System>;
};


export type QueryAccessoriesArgs = {
  dn: Scalars['String'];
  systemId: Scalars['String'];
};


export type QueryBasketArgs = {
  id: Scalars['String'];
};


export type QuerySystemArgs = {
  id: Scalars['String'];
};


export type QuerySystemConfigArgs = {
  systemConfigId: Scalars['String'];
};


export type QuerySystemConfigsArgs = {
  limitToCustomer?: InputMaybe<Scalars['Boolean']>;
};

export type RemoveBasketLineResult = BasketLine | BasketLineNotFoundError;

export type RemoveConfigVariantLineResult = ConfigNotFoundError | ConfigVariantLine;

export type SendBasketToOrderMutationResult = BasketNotFoundError | SendBasketToOrderResult;

export type SendBasketToOrderResult = {
  __typename?: 'SendBasketToOrderResult';
  errors: Array<Scalars['String']>;
};

export type System = {
  __typename?: 'System';
  /** Description of the system */
  description?: Maybe<Scalars['String']>;
  /** ID of the system */
  id: Scalars['String'];
  /** Connected system modules */
  modules: Array<SystemModule>;
  /** Name of the system */
  name?: Maybe<Scalars['String']>;
  systemConfigs: SystemConfig;
};

export type SystemConfig = {
  __typename?: 'SystemConfig';
  basketLines: Array<BasketLine>;
  /** Debug Information */
  calculationErrors: Array<CalculationError>;
  /** Company this Configuration is bound to */
  companyId: Scalars['String'];
  configVariantLines: Array<ConfigVariantLine>;
  createdAt: Scalars['DateTime'];
  /** Customer who created the Configuration */
  customerId: Scalars['String'];
  expertCheckMessages: Array<ExpertCheckMessage>;
  /** Status of the expert check */
  expertCheckStatus: ExpertCheckStatus;
  id: Scalars['String'];
  /** System Modules */
  moduleFieldValues: Array<ModuleFieldValue>;
  /** Name of the system config */
  name: Scalars['String'];
  /** The system that is being configured, can be null if the system does not exist anymore */
  system?: Maybe<System>;
  /** The id of the system source */
  systemId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SystemModule = {
  __typename?: 'SystemModule';
  /** Identification code */
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** Connected module fields */
  fields?: Maybe<Array<SystemModuleField>>;
  id: Scalars['String'];
  /** Name of the module */
  name: Scalars['String'];
  /** Boolean to make the module optional */
  optional?: Maybe<Scalars['Boolean']>;
  /** Connected tooltip */
  tooltip?: Maybe<SystemTooltip>;
  updatedAt: Scalars['DateTime'];
};

export type SystemModuleField = {
  __typename?: 'SystemModuleField';
  /** Identifier of the module field */
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  defaultValue?: Maybe<Scalars['String']>;
  /** Connected field options */
  hideConditions?: Maybe<Array<FieldHideCondition>>;
  id: Scalars['String'];
  /** Name of the module field */
  name: Scalars['String'];
  /** field options */
  options?: Maybe<Array<Scalars['String']>>;
  /** Endpoint to get dynamic fields from, for debug reasons included, replace %code% with the value of the code */
  selectEndpoint?: Maybe<Scalars['String']>;
  /** Connected tooltip */
  tooltip: SystemTooltip;
  /** Unit of measurement of the module field */
  unit?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  /** Connected validation object */
  validation: SystemModuleFieldValidation;
  valueType: ValueType;
};

export type SystemModuleFieldValidation = {
  __typename?: 'SystemModuleFieldValidation';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  /** Max allowed value for field */
  max?: Maybe<Scalars['String']>;
  /** Min allowed value for field */
  min?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type SystemNotFoundError = {
  __typename?: 'SystemNotFoundError';
  message: Scalars['String'];
};

export type SystemTooltip = {
  __typename?: 'SystemTooltip';
  createdAt: Scalars['DateTime'];
  /** Description of the tooltip */
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Image url of the tooltip */
  image?: Maybe<Scalars['String']>;
  /** Title of the tooltip */
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type TranslatedString = {
  __typename?: 'TranslatedString';
  de?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
};

export type UpdateBasketLineInput = {
  id: Scalars['String'];
  quantity: Scalars['Int'];
};

export type UpdateConfigVariantLineInput = {
  id: Scalars['String'];
  /** Amount of variants */
  quantity: Scalars['Int'];
};

export type UpdateConfigVariantLineResult = ConfigNotFoundError | ConfigVariantLine;

export type UpdateSystemConfigInput = {
  /** If passed updates the ModuleFieldsValues */
  moduleFieldValues?: InputMaybe<Array<InputModuleFieldValue>>;
  /** If passed updates the name of the system config */
  name?: InputMaybe<Scalars['String']>;
  /** ID of the system config to update */
  systemConfigId: Scalars['String'];
};

export type UpdateSystemConfigResult = ConfigNotFoundError | SystemConfig | SystemNotFoundError;

/** Type of the module field */
export enum ValueType {
  Bool = 'BOOL',
  DynamicSelect = 'DYNAMIC_SELECT',
  Number = 'NUMBER',
  Select = 'SELECT'
}

export type Variant = {
  __typename?: 'Variant';
  absolutePath?: Maybe<TranslatedString>;
  configVariantLines: Array<ConfigVariantLine>;
  configuratorAttributes: Array<Attribute>;
  description?: Maybe<TranslatedString>;
  images?: Maybe<Array<Image>>;
  name?: Maybe<TranslatedString>;
  price?: Maybe<CustomerSpecificPrice>;
  sku?: Maybe<Scalars['String']>;
  slug?: Maybe<TranslatedString>;
  taxCategory?: Maybe<Scalars['String']>;
  title?: Maybe<TranslatedString>;
  variantId: Scalars['String'];
};

export type VariantNotFoundError = {
  __typename?: 'VariantNotFoundError';
  message: Scalars['String'];
};

export type ConfigurationFragment = { __typename?: 'SystemConfig', id: string, name: string, expertCheckStatus: ExpertCheckStatus, moduleFieldValues: Array<{ __typename?: 'ModuleFieldValue', value: string, fieldId: string }>, configVariantLines: Array<{ __typename?: 'ConfigVariantLine', id: string, configurationSourceKey: string, quantity: number, variant: { __typename?: 'Variant', variantId: string, sku?: string | null, name?: { __typename?: 'TranslatedString', de?: string | null, en?: string | null } | null, images?: Array<{ __typename?: 'Image', fileName?: string | null, fileSize?: string | null, frontendFullPath?: string | null }> | null, price?: { __typename?: 'CustomerSpecificPrice', basePrice: number, price: number } | null } }> };

export type LanguageFragment = { __typename?: 'TranslatedString', de?: string | null, en?: string | null };

export type SystemFragment = { __typename?: 'System', id: string, name?: string | null, modules: Array<{ __typename?: 'SystemModule', id: string, name: string, optional?: boolean | null, code?: string | null, tooltip?: { __typename?: 'SystemTooltip', title?: string | null, description?: string | null, image?: string | null } | null, fields?: Array<{ __typename?: 'SystemModuleField', id: string, name: string, valueType: ValueType, unit?: string | null, options?: Array<string> | null, defaultValue?: string | null, code: string, validation: { __typename?: 'SystemModuleFieldValidation', min?: string | null, max?: string | null }, hideConditions?: Array<{ __typename?: 'FieldHideCondition', field: string, operator: string, value: string, disableIfHidden?: boolean | null }> | null, tooltip: { __typename?: 'SystemTooltip', title?: string | null, description?: string | null, image?: string | null } }> | null }> };

export type SystemTooltipFragment = { __typename?: 'SystemTooltip', title?: string | null, description?: string | null, image?: string | null };

export type AddConfigToBasketMutationVariables = Exact<{
  configId: Scalars['String'];
}>;


export type AddConfigToBasketMutation = { __typename?: 'Mutation', addConfigToBasket: { __typename: 'Basket', id: string } | { __typename: 'ConfigNotFoundError', message: string } };

export type ClearBasketMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearBasketMutation = { __typename?: 'Mutation', clearBasket: { __typename: 'Basket', id: string } | { __typename: 'BasketNotFoundError', message: string } };

export type CreateConfigVariantLineMutationVariables = Exact<{
  input: CreateConfigVariantLineInput;
}>;


export type CreateConfigVariantLineMutation = { __typename?: 'Mutation', createConfigVariantLine: { __typename: 'ConfigVariantLine' } | { __typename: 'VariantNotFoundError' } };

export type CreateSystemConfigMutationVariables = Exact<{
  systemId: Scalars['String'];
}>;


export type CreateSystemConfigMutation = { __typename?: 'Mutation', createSystemConfig: { __typename: 'SystemConfig', id: string, name: string } | { __typename: 'SystemNotFoundError', message: string } };

export type DeleteSystemConfigMutationVariables = Exact<{
  systemConfigId: Scalars['String'];
}>;


export type DeleteSystemConfigMutation = { __typename?: 'Mutation', deleteSystemConfig: { __typename: 'ConfigNotFoundError', message: string } | { __typename: 'SystemConfig', id: string, name: string } };

export type RemoveBasketLineMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveBasketLineMutation = { __typename?: 'Mutation', removeBasketLine: { __typename?: 'BasketLine', id: string } | { __typename?: 'BasketLineNotFoundError', message: string } };

export type RemoveConfigVariantLineMutationVariables = Exact<{
  configVariantLineId: Scalars['String'];
}>;


export type RemoveConfigVariantLineMutation = { __typename?: 'Mutation', removeConfigVariantLine: { __typename?: 'ConfigNotFoundError', message: string } | { __typename?: 'ConfigVariantLine', id: string } };

export type RequestExpertCheckMutationVariables = Exact<{
  id: Scalars['String'];
  message: Scalars['String'];
}>;


export type RequestExpertCheckMutation = { __typename?: 'Mutation', requestExpertCheck: { __typename: 'ConfigNotFoundError', message: string } | { __typename: 'SystemConfig', id: string, name: string } | { __typename: 'SystemNotFoundError', message: string } };

export type SendBasketToOrderMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type SendBasketToOrderMutation = { __typename?: 'Mutation', sendBasketToOrder: { __typename: 'BasketNotFoundError' } | { __typename: 'SendBasketToOrderResult' } };

export type UpdateBasketLineMutationVariables = Exact<{
  input: UpdateBasketLineInput;
}>;


export type UpdateBasketLineMutation = { __typename?: 'Mutation', updateBasketLine: { __typename?: 'BasketLine', id: string, quantity: number } };

export type UpdateConfigVariantLineMutationVariables = Exact<{
  configVariantLineId: Scalars['String'];
  quantity: Scalars['Int'];
}>;


export type UpdateConfigVariantLineMutation = { __typename?: 'Mutation', updateConfigVariantLine: { __typename?: 'ConfigNotFoundError', message: string } | { __typename?: 'ConfigVariantLine', id: string, quantity: number, variant: { __typename?: 'Variant', variantId: string } } };

export type UpdateSystemConfigMutationVariables = Exact<{
  config: UpdateSystemConfigInput;
}>;


export type UpdateSystemConfigMutation = { __typename?: 'Mutation', updateSystemConfig: { __typename: 'ConfigNotFoundError', message: string } | { __typename: 'SystemConfig', id: string, name: string, expertCheckStatus: ExpertCheckStatus, system?: { __typename?: 'System', id: string, name?: string | null, modules: Array<{ __typename?: 'SystemModule', id: string, name: string, optional?: boolean | null, code?: string | null, tooltip?: { __typename?: 'SystemTooltip', title?: string | null, description?: string | null, image?: string | null } | null, fields?: Array<{ __typename?: 'SystemModuleField', id: string, name: string, valueType: ValueType, unit?: string | null, options?: Array<string> | null, defaultValue?: string | null, code: string, validation: { __typename?: 'SystemModuleFieldValidation', min?: string | null, max?: string | null }, hideConditions?: Array<{ __typename?: 'FieldHideCondition', field: string, operator: string, value: string, disableIfHidden?: boolean | null }> | null, tooltip: { __typename?: 'SystemTooltip', title?: string | null, description?: string | null, image?: string | null } }> | null }> } | null, calculationErrors: Array<{ __typename?: 'CalculationError', configurationSourceKey: string, type: string, message?: string | null, filter?: string | null }>, moduleFieldValues: Array<{ __typename?: 'ModuleFieldValue', value: string, fieldId: string }>, configVariantLines: Array<{ __typename?: 'ConfigVariantLine', id: string, configurationSourceKey: string, quantity: number, variant: { __typename?: 'Variant', variantId: string, sku?: string | null, name?: { __typename?: 'TranslatedString', de?: string | null, en?: string | null } | null, images?: Array<{ __typename?: 'Image', fileName?: string | null, fileSize?: string | null, frontendFullPath?: string | null }> | null, price?: { __typename?: 'CustomerSpecificPrice', basePrice: number, price: number } | null } }> } | { __typename: 'SystemNotFoundError', message: string } };

export type AccessoriesQueryVariables = Exact<{
  systemId: Scalars['String'];
  dn: Scalars['String'];
}>;


export type AccessoriesQuery = { __typename?: 'Query', accessories: Array<{ __typename?: 'Variant', variantId: string, sku?: string | null, name?: { __typename?: 'TranslatedString', de?: string | null, en?: string | null } | null, slug?: { __typename?: 'TranslatedString', de?: string | null, en?: string | null } | null, absolutePath?: { __typename?: 'TranslatedString', de?: string | null, en?: string | null } | null, images?: Array<{ __typename?: 'Image', fileName?: string | null, fileSize?: string | null, frontendFullPath?: string | null }> | null, price?: { __typename?: 'CustomerSpecificPrice', basePrice: number, price: number } | null }> };

export type ActiveBasketQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveBasketQuery = { __typename?: 'Query', activeBasket?: { __typename?: 'Basket', id: string, basketLines: Array<{ __typename?: 'BasketLine', id: string, totalCost: number, quantity: number, systemConfig: { __typename?: 'SystemConfig', id: string, name: string }, basket: { __typename?: 'Basket', id: string, state: string } }> } | null };

export type ActiveCustomerQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveCustomerQuery = { __typename?: 'Query', activeCustomer?: { __typename?: 'Customer', id: string, firstName: string, lastName: string, emailAddress: string, title: string } | null };

export type ContactPersonQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactPersonQuery = { __typename?: 'Query', contactPerson?: { __typename?: 'ContactPerson', image?: string | null, name?: string | null, position?: string | null, phone?: string | null, mobile?: string | null, email?: string | null } | null };

export type SystemConfigByIdQueryVariables = Exact<{
  systemConfigId: Scalars['String'];
}>;


export type SystemConfigByIdQuery = { __typename?: 'Query', systemConfig?: { __typename?: 'SystemConfig', id: string, name: string, expertCheckStatus: ExpertCheckStatus, system?: { __typename?: 'System', id: string, name?: string | null, modules: Array<{ __typename?: 'SystemModule', id: string, name: string, optional?: boolean | null, code?: string | null, tooltip?: { __typename?: 'SystemTooltip', title?: string | null, description?: string | null, image?: string | null } | null, fields?: Array<{ __typename?: 'SystemModuleField', id: string, name: string, valueType: ValueType, unit?: string | null, options?: Array<string> | null, defaultValue?: string | null, code: string, validation: { __typename?: 'SystemModuleFieldValidation', min?: string | null, max?: string | null }, hideConditions?: Array<{ __typename?: 'FieldHideCondition', field: string, operator: string, value: string, disableIfHidden?: boolean | null }> | null, tooltip: { __typename?: 'SystemTooltip', title?: string | null, description?: string | null, image?: string | null } }> | null }> } | null, moduleFieldValues: Array<{ __typename?: 'ModuleFieldValue', value: string, fieldId: string }>, configVariantLines: Array<{ __typename?: 'ConfigVariantLine', id: string, configurationSourceKey: string, quantity: number, variant: { __typename?: 'Variant', variantId: string, sku?: string | null, name?: { __typename?: 'TranslatedString', de?: string | null, en?: string | null } | null, images?: Array<{ __typename?: 'Image', fileName?: string | null, fileSize?: string | null, frontendFullPath?: string | null }> | null, price?: { __typename?: 'CustomerSpecificPrice', basePrice: number, price: number } | null } }> } | null };

export type SystemConfigsQueryVariables = Exact<{ [key: string]: never; }>;


export type SystemConfigsQuery = { __typename?: 'Query', systemConfigs: Array<{ __typename?: 'SystemConfig', id: string, name: string, moduleFieldValues: Array<{ __typename?: 'ModuleFieldValue', id: string, value: string, fieldId: string }>, configVariantLines: Array<{ __typename?: 'ConfigVariantLine', id: string, quantity: number, variant: { __typename?: 'Variant', variantId: string, sku?: string | null, name?: { __typename?: 'TranslatedString', de?: string | null, en?: string | null } | null, images?: Array<{ __typename?: 'Image', fileName?: string | null, fileSize?: string | null, frontendFullPath?: string | null }> | null, price?: { __typename?: 'CustomerSpecificPrice', basePrice: number, price: number } | null } }> }> };

export type SystemsQueryVariables = Exact<{ [key: string]: never; }>;


export type SystemsQuery = { __typename?: 'Query', systems: Array<{ __typename?: 'System', id: string, name?: string | null, modules: Array<{ __typename?: 'SystemModule', name: string }> }> };

export const ConfigurationFragmentDoc = `
    fragment Configuration on SystemConfig {
  id
  name
  moduleFieldValues {
    value
    fieldId
  }
  expertCheckStatus
  configVariantLines {
    id
    configurationSourceKey
    variant {
      variantId
      sku
      name {
        de
        en
      }
      images {
        fileName
        fileSize
        frontendFullPath
      }
      price {
        basePrice
        price
      }
    }
    quantity
  }
}
    `;
export const LanguageFragmentDoc = `
    fragment Language on TranslatedString {
  de
  en
}
    `;
export const SystemTooltipFragmentDoc = `
    fragment SystemTooltip on SystemTooltip {
  title
  description
  image
}
    `;
export const SystemFragmentDoc = `
    fragment System on System {
  id
  name
  modules {
    id
    name
    optional
    code
    tooltip {
      ...SystemTooltip
    }
    fields {
      id
      validation {
        min
        max
      }
      name
      valueType
      unit
      options
      defaultValue
      hideConditions {
        field
        operator
        value
        disableIfHidden
      }
      code
      tooltip {
        ...SystemTooltip
      }
    }
  }
}
    `;
export const AddConfigToBasketDocument = `
    mutation AddConfigToBasket($configId: String!) {
  addConfigToBasket(configId: $configId) {
    ... on Basket {
      id
    }
    ... on ConfigNotFoundError {
      message
    }
    __typename
  }
}
    `;
export const useAddConfigToBasketMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddConfigToBasketMutation, TError, AddConfigToBasketMutationVariables, TContext>) =>
    useMutation<AddConfigToBasketMutation, TError, AddConfigToBasketMutationVariables, TContext>(
      ['AddConfigToBasket'],
      (variables?: AddConfigToBasketMutationVariables) => fetchConfigurator<AddConfigToBasketMutation, AddConfigToBasketMutationVariables>(AddConfigToBasketDocument, variables)(),
      options
    );
useAddConfigToBasketMutation.fetcher = (variables: AddConfigToBasketMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<AddConfigToBasketMutation, AddConfigToBasketMutationVariables>(AddConfigToBasketDocument, variables, options);
export const ClearBasketDocument = `
    mutation ClearBasket {
  clearBasket {
    __typename
    ... on Basket {
      id
    }
    ... on BasketNotFoundError {
      message
    }
  }
}
    `;
export const useClearBasketMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ClearBasketMutation, TError, ClearBasketMutationVariables, TContext>) =>
    useMutation<ClearBasketMutation, TError, ClearBasketMutationVariables, TContext>(
      ['ClearBasket'],
      (variables?: ClearBasketMutationVariables) => fetchConfigurator<ClearBasketMutation, ClearBasketMutationVariables>(ClearBasketDocument, variables)(),
      options
    );
useClearBasketMutation.fetcher = (variables?: ClearBasketMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<ClearBasketMutation, ClearBasketMutationVariables>(ClearBasketDocument, variables, options);
export const CreateConfigVariantLineDocument = `
    mutation createConfigVariantLine($input: CreateConfigVariantLineInput!) {
  createConfigVariantLine(createConfigVariantLineInput: $input) {
    __typename
  }
}
    `;
export const useCreateConfigVariantLineMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateConfigVariantLineMutation, TError, CreateConfigVariantLineMutationVariables, TContext>) =>
    useMutation<CreateConfigVariantLineMutation, TError, CreateConfigVariantLineMutationVariables, TContext>(
      ['createConfigVariantLine'],
      (variables?: CreateConfigVariantLineMutationVariables) => fetchConfigurator<CreateConfigVariantLineMutation, CreateConfigVariantLineMutationVariables>(CreateConfigVariantLineDocument, variables)(),
      options
    );
useCreateConfigVariantLineMutation.fetcher = (variables: CreateConfigVariantLineMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<CreateConfigVariantLineMutation, CreateConfigVariantLineMutationVariables>(CreateConfigVariantLineDocument, variables, options);
export const CreateSystemConfigDocument = `
    mutation CreateSystemConfig($systemId: String!) {
  createSystemConfig(systemId: $systemId) {
    __typename
    ... on SystemConfig {
      id
      name
    }
    ... on SystemNotFoundError {
      message
    }
  }
}
    `;
export const useCreateSystemConfigMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSystemConfigMutation, TError, CreateSystemConfigMutationVariables, TContext>) =>
    useMutation<CreateSystemConfigMutation, TError, CreateSystemConfigMutationVariables, TContext>(
      ['CreateSystemConfig'],
      (variables?: CreateSystemConfigMutationVariables) => fetchConfigurator<CreateSystemConfigMutation, CreateSystemConfigMutationVariables>(CreateSystemConfigDocument, variables)(),
      options
    );
useCreateSystemConfigMutation.fetcher = (variables: CreateSystemConfigMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<CreateSystemConfigMutation, CreateSystemConfigMutationVariables>(CreateSystemConfigDocument, variables, options);
export const DeleteSystemConfigDocument = `
    mutation DeleteSystemConfig($systemConfigId: String!) {
  deleteSystemConfig(systemConfigId: $systemConfigId) {
    ... on SystemConfig {
      id
      name
    }
    ... on ConfigNotFoundError {
      message
    }
    __typename
  }
}
    `;
export const useDeleteSystemConfigMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteSystemConfigMutation, TError, DeleteSystemConfigMutationVariables, TContext>) =>
    useMutation<DeleteSystemConfigMutation, TError, DeleteSystemConfigMutationVariables, TContext>(
      ['DeleteSystemConfig'],
      (variables?: DeleteSystemConfigMutationVariables) => fetchConfigurator<DeleteSystemConfigMutation, DeleteSystemConfigMutationVariables>(DeleteSystemConfigDocument, variables)(),
      options
    );
useDeleteSystemConfigMutation.fetcher = (variables: DeleteSystemConfigMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<DeleteSystemConfigMutation, DeleteSystemConfigMutationVariables>(DeleteSystemConfigDocument, variables, options);
export const RemoveBasketLineDocument = `
    mutation RemoveBasketLine($id: String!) {
  removeBasketLine(id: $id) {
    ... on BasketLine {
      id
    }
    ... on BasketLineNotFoundError {
      message
    }
  }
}
    `;
export const useRemoveBasketLineMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveBasketLineMutation, TError, RemoveBasketLineMutationVariables, TContext>) =>
    useMutation<RemoveBasketLineMutation, TError, RemoveBasketLineMutationVariables, TContext>(
      ['RemoveBasketLine'],
      (variables?: RemoveBasketLineMutationVariables) => fetchConfigurator<RemoveBasketLineMutation, RemoveBasketLineMutationVariables>(RemoveBasketLineDocument, variables)(),
      options
    );
useRemoveBasketLineMutation.fetcher = (variables: RemoveBasketLineMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<RemoveBasketLineMutation, RemoveBasketLineMutationVariables>(RemoveBasketLineDocument, variables, options);
export const RemoveConfigVariantLineDocument = `
    mutation RemoveConfigVariantLine($configVariantLineId: String!) {
  removeConfigVariantLine(id: $configVariantLineId) {
    ... on ConfigVariantLine {
      id
    }
    ... on ConfigNotFoundError {
      message
    }
  }
}
    `;
export const useRemoveConfigVariantLineMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemoveConfigVariantLineMutation, TError, RemoveConfigVariantLineMutationVariables, TContext>) =>
    useMutation<RemoveConfigVariantLineMutation, TError, RemoveConfigVariantLineMutationVariables, TContext>(
      ['RemoveConfigVariantLine'],
      (variables?: RemoveConfigVariantLineMutationVariables) => fetchConfigurator<RemoveConfigVariantLineMutation, RemoveConfigVariantLineMutationVariables>(RemoveConfigVariantLineDocument, variables)(),
      options
    );
useRemoveConfigVariantLineMutation.fetcher = (variables: RemoveConfigVariantLineMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<RemoveConfigVariantLineMutation, RemoveConfigVariantLineMutationVariables>(RemoveConfigVariantLineDocument, variables, options);
export const RequestExpertCheckDocument = `
    mutation RequestExpertCheck($id: String!, $message: String!) {
  requestExpertCheck(systemConfigId: $id, message: $message) {
    __typename
    ... on SystemConfig {
      id
      name
    }
    ... on SystemNotFoundError {
      message
    }
    ... on ConfigNotFoundError {
      message
    }
  }
}
    `;
export const useRequestExpertCheckMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RequestExpertCheckMutation, TError, RequestExpertCheckMutationVariables, TContext>) =>
    useMutation<RequestExpertCheckMutation, TError, RequestExpertCheckMutationVariables, TContext>(
      ['RequestExpertCheck'],
      (variables?: RequestExpertCheckMutationVariables) => fetchConfigurator<RequestExpertCheckMutation, RequestExpertCheckMutationVariables>(RequestExpertCheckDocument, variables)(),
      options
    );
useRequestExpertCheckMutation.fetcher = (variables: RequestExpertCheckMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<RequestExpertCheckMutation, RequestExpertCheckMutationVariables>(RequestExpertCheckDocument, variables, options);
export const SendBasketToOrderDocument = `
    mutation SendBasketToOrder($id: String!) {
  sendBasketToOrder(id: $id) {
    __typename
  }
}
    `;
export const useSendBasketToOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SendBasketToOrderMutation, TError, SendBasketToOrderMutationVariables, TContext>) =>
    useMutation<SendBasketToOrderMutation, TError, SendBasketToOrderMutationVariables, TContext>(
      ['SendBasketToOrder'],
      (variables?: SendBasketToOrderMutationVariables) => fetchConfigurator<SendBasketToOrderMutation, SendBasketToOrderMutationVariables>(SendBasketToOrderDocument, variables)(),
      options
    );
useSendBasketToOrderMutation.fetcher = (variables: SendBasketToOrderMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<SendBasketToOrderMutation, SendBasketToOrderMutationVariables>(SendBasketToOrderDocument, variables, options);
export const UpdateBasketLineDocument = `
    mutation UpdateBasketLine($input: UpdateBasketLineInput!) {
  updateBasketLine(updateBasketLineInput: $input) {
    id
    quantity
  }
}
    `;
export const useUpdateBasketLineMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateBasketLineMutation, TError, UpdateBasketLineMutationVariables, TContext>) =>
    useMutation<UpdateBasketLineMutation, TError, UpdateBasketLineMutationVariables, TContext>(
      ['UpdateBasketLine'],
      (variables?: UpdateBasketLineMutationVariables) => fetchConfigurator<UpdateBasketLineMutation, UpdateBasketLineMutationVariables>(UpdateBasketLineDocument, variables)(),
      options
    );
useUpdateBasketLineMutation.fetcher = (variables: UpdateBasketLineMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<UpdateBasketLineMutation, UpdateBasketLineMutationVariables>(UpdateBasketLineDocument, variables, options);
export const UpdateConfigVariantLineDocument = `
    mutation UpdateConfigVariantLine($configVariantLineId: String!, $quantity: Int!) {
  updateConfigVariantLine(
    updateConfigVariantLineInput: {id: $configVariantLineId, quantity: $quantity}
  ) {
    ... on ConfigVariantLine {
      id
      quantity
      variant {
        variantId
      }
    }
    ... on ConfigNotFoundError {
      message
    }
  }
}
    `;
export const useUpdateConfigVariantLineMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateConfigVariantLineMutation, TError, UpdateConfigVariantLineMutationVariables, TContext>) =>
    useMutation<UpdateConfigVariantLineMutation, TError, UpdateConfigVariantLineMutationVariables, TContext>(
      ['UpdateConfigVariantLine'],
      (variables?: UpdateConfigVariantLineMutationVariables) => fetchConfigurator<UpdateConfigVariantLineMutation, UpdateConfigVariantLineMutationVariables>(UpdateConfigVariantLineDocument, variables)(),
      options
    );
useUpdateConfigVariantLineMutation.fetcher = (variables: UpdateConfigVariantLineMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<UpdateConfigVariantLineMutation, UpdateConfigVariantLineMutationVariables>(UpdateConfigVariantLineDocument, variables, options);
export const UpdateSystemConfigDocument = `
    mutation UpdateSystemConfig($config: UpdateSystemConfigInput!) {
  updateSystemConfig(config: $config) {
    ... on SystemConfig {
      ...Configuration
      system {
        ...System
      }
      calculationErrors {
        configurationSourceKey
        type
        message
        filter
      }
    }
    ... on SystemNotFoundError {
      message
    }
    ... on ConfigNotFoundError {
      message
    }
    __typename
  }
}
    ${ConfigurationFragmentDoc}
${SystemFragmentDoc}
${SystemTooltipFragmentDoc}`;
export const useUpdateSystemConfigMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSystemConfigMutation, TError, UpdateSystemConfigMutationVariables, TContext>) =>
    useMutation<UpdateSystemConfigMutation, TError, UpdateSystemConfigMutationVariables, TContext>(
      ['UpdateSystemConfig'],
      (variables?: UpdateSystemConfigMutationVariables) => fetchConfigurator<UpdateSystemConfigMutation, UpdateSystemConfigMutationVariables>(UpdateSystemConfigDocument, variables)(),
      options
    );
useUpdateSystemConfigMutation.fetcher = (variables: UpdateSystemConfigMutationVariables, options?: RequestInit['headers']) => fetchConfigurator<UpdateSystemConfigMutation, UpdateSystemConfigMutationVariables>(UpdateSystemConfigDocument, variables, options);
export const AccessoriesDocument = `
    query Accessories($systemId: String!, $dn: String!) {
  accessories(systemId: $systemId, dn: $dn) {
    variantId
    sku
    name {
      ...Language
    }
    slug {
      ...Language
    }
    absolutePath {
      ...Language
    }
    images {
      fileName
      fileSize
      frontendFullPath
    }
    price {
      basePrice
      price
    }
  }
}
    ${LanguageFragmentDoc}`;
export const useAccessoriesQuery = <
      TData = AccessoriesQuery,
      TError = unknown
    >(
      variables: AccessoriesQueryVariables,
      options?: UseQueryOptions<AccessoriesQuery, TError, TData>
    ) =>
    useQuery<AccessoriesQuery, TError, TData>(
      ['Accessories', variables],
      fetchConfigurator<AccessoriesQuery, AccessoriesQueryVariables>(AccessoriesDocument, variables),
      options
    );

useAccessoriesQuery.getKey = (variables: AccessoriesQueryVariables) => ['Accessories', variables];
;

useAccessoriesQuery.fetcher = (variables: AccessoriesQueryVariables, options?: RequestInit['headers']) => fetchConfigurator<AccessoriesQuery, AccessoriesQueryVariables>(AccessoriesDocument, variables, options);
export const ActiveBasketDocument = `
    query ActiveBasket {
  activeBasket {
    id
    basketLines {
      id
      totalCost
      quantity
      systemConfig {
        id
        name
      }
      basket {
        id
        state
      }
    }
  }
}
    `;
export const useActiveBasketQuery = <
      TData = ActiveBasketQuery,
      TError = unknown
    >(
      variables?: ActiveBasketQueryVariables,
      options?: UseQueryOptions<ActiveBasketQuery, TError, TData>
    ) =>
    useQuery<ActiveBasketQuery, TError, TData>(
      variables === undefined ? ['ActiveBasket'] : ['ActiveBasket', variables],
      fetchConfigurator<ActiveBasketQuery, ActiveBasketQueryVariables>(ActiveBasketDocument, variables),
      options
    );

useActiveBasketQuery.getKey = (variables?: ActiveBasketQueryVariables) => variables === undefined ? ['ActiveBasket'] : ['ActiveBasket', variables];
;

useActiveBasketQuery.fetcher = (variables?: ActiveBasketQueryVariables, options?: RequestInit['headers']) => fetchConfigurator<ActiveBasketQuery, ActiveBasketQueryVariables>(ActiveBasketDocument, variables, options);
export const ActiveCustomerDocument = `
    query ActiveCustomer {
  activeCustomer {
    id
    firstName
    lastName
    emailAddress
    title
  }
}
    `;
export const useActiveCustomerQuery = <
      TData = ActiveCustomerQuery,
      TError = unknown
    >(
      variables?: ActiveCustomerQueryVariables,
      options?: UseQueryOptions<ActiveCustomerQuery, TError, TData>
    ) =>
    useQuery<ActiveCustomerQuery, TError, TData>(
      variables === undefined ? ['ActiveCustomer'] : ['ActiveCustomer', variables],
      fetchConfigurator<ActiveCustomerQuery, ActiveCustomerQueryVariables>(ActiveCustomerDocument, variables),
      options
    );

useActiveCustomerQuery.getKey = (variables?: ActiveCustomerQueryVariables) => variables === undefined ? ['ActiveCustomer'] : ['ActiveCustomer', variables];
;

useActiveCustomerQuery.fetcher = (variables?: ActiveCustomerQueryVariables, options?: RequestInit['headers']) => fetchConfigurator<ActiveCustomerQuery, ActiveCustomerQueryVariables>(ActiveCustomerDocument, variables, options);
export const ContactPersonDocument = `
    query ContactPerson {
  contactPerson {
    image
    name
    position
    phone
    mobile
    email
  }
}
    `;
export const useContactPersonQuery = <
      TData = ContactPersonQuery,
      TError = unknown
    >(
      variables?: ContactPersonQueryVariables,
      options?: UseQueryOptions<ContactPersonQuery, TError, TData>
    ) =>
    useQuery<ContactPersonQuery, TError, TData>(
      variables === undefined ? ['ContactPerson'] : ['ContactPerson', variables],
      fetchConfigurator<ContactPersonQuery, ContactPersonQueryVariables>(ContactPersonDocument, variables),
      options
    );

useContactPersonQuery.getKey = (variables?: ContactPersonQueryVariables) => variables === undefined ? ['ContactPerson'] : ['ContactPerson', variables];
;

useContactPersonQuery.fetcher = (variables?: ContactPersonQueryVariables, options?: RequestInit['headers']) => fetchConfigurator<ContactPersonQuery, ContactPersonQueryVariables>(ContactPersonDocument, variables, options);
export const SystemConfigByIdDocument = `
    query SystemConfigById($systemConfigId: String!) {
  systemConfig(systemConfigId: $systemConfigId) {
    ...Configuration
    system {
      ...System
    }
  }
}
    ${ConfigurationFragmentDoc}
${SystemFragmentDoc}
${SystemTooltipFragmentDoc}`;
export const useSystemConfigByIdQuery = <
      TData = SystemConfigByIdQuery,
      TError = unknown
    >(
      variables: SystemConfigByIdQueryVariables,
      options?: UseQueryOptions<SystemConfigByIdQuery, TError, TData>
    ) =>
    useQuery<SystemConfigByIdQuery, TError, TData>(
      ['SystemConfigById', variables],
      fetchConfigurator<SystemConfigByIdQuery, SystemConfigByIdQueryVariables>(SystemConfigByIdDocument, variables),
      options
    );

useSystemConfigByIdQuery.getKey = (variables: SystemConfigByIdQueryVariables) => ['SystemConfigById', variables];
;

useSystemConfigByIdQuery.fetcher = (variables: SystemConfigByIdQueryVariables, options?: RequestInit['headers']) => fetchConfigurator<SystemConfigByIdQuery, SystemConfigByIdQueryVariables>(SystemConfigByIdDocument, variables, options);
export const SystemConfigsDocument = `
    query SystemConfigs {
  systemConfigs {
    id
    name
    moduleFieldValues {
      id
      value
      fieldId
    }
    configVariantLines {
      id
      variant {
        variantId
        sku
        name {
          ...Language
        }
        images {
          fileName
          fileSize
          frontendFullPath
        }
        price {
          basePrice
          price
        }
      }
      quantity
    }
  }
}
    ${LanguageFragmentDoc}`;
export const useSystemConfigsQuery = <
      TData = SystemConfigsQuery,
      TError = unknown
    >(
      variables?: SystemConfigsQueryVariables,
      options?: UseQueryOptions<SystemConfigsQuery, TError, TData>
    ) =>
    useQuery<SystemConfigsQuery, TError, TData>(
      variables === undefined ? ['SystemConfigs'] : ['SystemConfigs', variables],
      fetchConfigurator<SystemConfigsQuery, SystemConfigsQueryVariables>(SystemConfigsDocument, variables),
      options
    );

useSystemConfigsQuery.getKey = (variables?: SystemConfigsQueryVariables) => variables === undefined ? ['SystemConfigs'] : ['SystemConfigs', variables];
;

useSystemConfigsQuery.fetcher = (variables?: SystemConfigsQueryVariables, options?: RequestInit['headers']) => fetchConfigurator<SystemConfigsQuery, SystemConfigsQueryVariables>(SystemConfigsDocument, variables, options);
export const SystemsDocument = `
    query Systems {
  systems {
    id
    name
    modules {
      name
    }
  }
}
    `;
export const useSystemsQuery = <
      TData = SystemsQuery,
      TError = unknown
    >(
      variables?: SystemsQueryVariables,
      options?: UseQueryOptions<SystemsQuery, TError, TData>
    ) =>
    useQuery<SystemsQuery, TError, TData>(
      variables === undefined ? ['Systems'] : ['Systems', variables],
      fetchConfigurator<SystemsQuery, SystemsQueryVariables>(SystemsDocument, variables),
      options
    );

useSystemsQuery.getKey = (variables?: SystemsQueryVariables) => variables === undefined ? ['Systems'] : ['Systems', variables];
;

useSystemsQuery.fetcher = (variables?: SystemsQueryVariables, options?: RequestInit['headers']) => fetchConfigurator<SystemsQuery, SystemsQueryVariables>(SystemsDocument, variables, options);