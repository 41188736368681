import { Center, Container, Heading, Text } from '@chakra-ui/react'
import NextImage from 'next/image'
import type React from 'react'
import { useIntl } from 'react-intl'

export interface ISmallScreenNoticeProps {

}

export const SmallScreenNotice: React.FC<ISmallScreenNoticeProps> = () => {
  const { formatMessage } = useIntl()
  return (
    <Container maxW="container.page">
      <Center h="calc(100vh - 20px)" flexDir="column" gap={6}>
        <NextImage
          src="/img/logo_header.svg"
          width="250px"
          height="130px"
        />
        <Heading textAlign="center">
          {formatMessage({ id: 'configurator-small-screen--heading' })}
        </Heading>
        <Text fontSize="lg" textAlign="center">
          {formatMessage({ id: 'configurator-small-screen--body' })}
          <br/>
          <br/>
          {formatMessage({ id: 'configurator-small-screen--footing' })}
        </Text>
      </Center>
    </Container>
  )
}
