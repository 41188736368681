import { Box, Center, HStack, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Spacer, Stack, Text } from '@chakra-ui/react'
import { Currency } from 'design_system/elements/Currency/Currency'
import { FiRsInfo } from 'design_system/elements/Icons3'
import type React from 'react'
import { useIntl } from 'react-intl'

export interface IPriceTooltipProps {
  prices?: Array<{
    name: string
    amount: number
  }>
  currencyCode?: string
}

export const PriceTooltip: React.FC<IPriceTooltipProps> = ({ prices, currencyCode = 'EUR' }) => {
  const { formatMessage } = useIntl()
  return (
    <Box>
      <HStack>
        <Text fontFamily="primary" fontSize={{ base: 'xl', xl: '2xl' }}>
          {formatMessage({ id: '--total-price' })}
        </Text>
        {prices && (
          <Popover placement="top">
            <PopoverTrigger>
              <Center as="span" fontSize="lg">
                <FiRsInfo/>
              </Center>
            </PopoverTrigger>
            <PopoverContent fontSize="md">
              <PopoverArrow/>
              <PopoverBody>
                <Stack fontFamily="primary">
                  {prices.map((price, index) => (
                    <HStack key={price.name + String(index)}>
                      <Text>{price.name}</Text>
                      <Spacer/>
                      <Currency amount={price.amount / 100} currencyCode={currencyCode}/>
                    </HStack>
                  ))}
                </Stack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </HStack>
      <Text fontFamily="secondary" fontSize="sm">{formatMessage({ id: '--without-tax' })}</Text>
    </Box>
  )
}
