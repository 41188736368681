import { Box, Button, Center, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, HStack, Spacer, Stack, StackDivider, Text, useDisclosure } from '@chakra-ui/react'
import { AmountSelect } from 'custom_components/compounds/AmountSelect/AmountSelect'
import { ConfirmOrderModal } from 'custom_components/compounds/ConfirmOrderModal/ConfirmOrderModal'
import { PriceTooltip } from 'custom_components/compounds/PriceTooltip/PriceTooltip'
import { Currency } from 'design_system/elements/Currency/Currency'
import { FiRsTrash } from 'design_system/elements/Icons2'
import { FiRsPlus } from 'design_system/elements/Icons3'
import { FiRsCalculator, FiRsCreditCard } from 'design_system/elements/Icons4'
import { NextLink } from 'design_system/utility/NextLink'
import { NEXT_PUBLIC_STOCKER_CART_URL } from 'helper/environment'
import type { useClearBasketMutation, useRemoveBasketLineMutation, useSendBasketToOrderMutation, useUpdateBasketLineMutation } from 'operations/configurator/generated/generated'
import type React from 'react'
import type { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'

export interface ICartItem {
  id: string
  configurationId: string
  name: string
  quantity: number
  price: number
}
export interface ISystemTotalCartProps {
  cartItems: ICartItem[]
  currencyCode?: string
  additionalItemsAmount?: number
  showShippingCost?: boolean
  updateBasketLine?: ReturnType<typeof useUpdateBasketLineMutation>
  removeBasketLine?: ReturnType<typeof useRemoveBasketLineMutation>
  sendBasketToOrder?: ReturnType<typeof useSendBasketToOrderMutation>
  clearBasket?: ReturnType<typeof useClearBasketMutation>
  basketId?: string
}

export const SystemTotalCart: React.FC<PropsWithChildren<ISystemTotalCartProps>> = ({ children, sendBasketToOrder, showShippingCost = false, additionalItemsAmount, cartItems, currencyCode = 'EUR', updateBasketLine, removeBasketLine, basketId, clearBasket }) => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const confirmOrderModal = useDisclosure({ defaultIsOpen: false })
  const { formatMessage } = useIntl()

  return (
    <>
      <Box onClick={onToggle}>
        {children}
      </Box>
      <Drawer
        size="lg"
        placement="right"
        onClose={onClose}
        isOpen={isOpen}
      >
        <DrawerOverlay
          motionProps={{
            initial: 'none',
            animate: 'none',
            exit: 'none',
          }}
        />
        {/* motionProps are set to none here because of chakra issue with drawers
          https://github.com/chakra-ui/chakra-ui/issues/7091 */}
        <DrawerContent
          motionProps={{
            initial: 'none',
            animate: 'none',
            exit: 'none',
          }}
        >
          <DrawerCloseButton color="white"/>
          <DrawerHeader bg="gray.500" color="white" borderBottomWidth="1px">
            <Text fontSize="xl" fontFamily="primary">
              {formatMessage({ id: '--configurator-cart' })}
            </Text>
          </DrawerHeader>
          <DrawerBody px={0} >
            <Flex flexDirection="column" h="100%">
              {/* cart items */}
              <Stack
                divider={<StackDivider borderColor="gray.200"/>}
                spacing={4}
                mt={2}
                overflow="auto"
              >
                {cartItems.map((item, index) => (
                  <Flex key={item.name + String(index)} px={6} align="center" direction="row" gap={3} fontSize="lg">
                    <AmountSelect
                      quantity={item.quantity}
                      selectData={{
                        min: 1,
                        max: 100,
                      }}
                      onSubmit={(quantity) => {
                        if (updateBasketLine) updateBasketLine.mutate({ input: { id: item.id, quantity } })
                      }}
                      onDelete={() => removeBasketLine?.mutate({ id: item.id })}
                    />
                    <NextLink fontWeight="bold" w="full" py={3} href={`/configurator?systemConfigId=${item.configurationId}`}>{item.name}</NextLink>
                    <Flex fontFamily="primary" fontSize="lg" align="center">
                      <Currency amount={item.price * item.quantity / 100} currencyCode={currencyCode}/>
                    </Flex>
                  </Flex>
                ))}
                {additionalItemsAmount && (
                  <Flex px={6} gap={3}>
                    <Center>
                      <Text fontWeight="bold">
                        {additionalItemsAmount}
                        {` ${formatMessage({ id: 'configurator-cart--additional-items' })}`}
                      </Text>
                    </Center>
                    <Spacer/>
                    <NextLink href={NEXT_PUBLIC_STOCKER_CART_URL}>
                      <Button colorScheme="secondaryText" variant="outline">
                        {formatMessage({ id: '--open-basket' })}
                      </Button>
                    </NextLink>
                  </Flex>
                )}
              </Stack>
              <Spacer/>
              <HStack p={6} justify="space-between">
                {/* TODO: implement confirm modal here like in Stocker */}
                <Button
                  colorScheme="secondaryText"
                  variant="outline"
                  leftIcon={<FiRsTrash/>}
                  onClick={() => clearBasket?.mutate({})}
                  isLoading={clearBasket?.isLoading}
                >{formatMessage({ id: 'cart--clear-cart' })}
                </Button>
                <NextLink href="/">
                  <Button colorScheme="secondaryText" variant="outline" leftIcon={<FiRsPlus/>}>
                    {formatMessage({ id: 'configurator-cart--add-another-system' })}
                  </Button>
                </NextLink>
              </HStack>
              <Divider borderColor="gray.200"/>
              {/* shipping */}
              {showShippingCost && (
                <HStack p={6} fontFamily="primary" fontSize="lg">
                  <Text>
                    {formatMessage({ id: '--shipping-costs' })}
                  </Text>
                  <Spacer/>
                  <Button colorScheme="secondaryText" variant="outline" leftIcon={<FiRsCalculator/>}>
                    {formatMessage({ id: '--calculate' })}
                  </Button>
                </HStack>
              )}
              <Divider borderColor="gray.200"/>
              {/* prices */}
              <HStack p={6} fontFamily="primary">
                <PriceTooltip/>
                <Spacer/>
                <Box fontSize="2xl">
                  <Currency amount={cartItems.reduce((acc, configuration) => acc + configuration.price * configuration.quantity, 0) / 100} currencyCode={currencyCode}/>
                </Box>
              </HStack>
              <HStack px={6} pb={6} justify="space-between">
                {/* <Button colorScheme="secondaryText" variant="outline" leftIcon={<FiRsUser/>}>Angebot Anfordern</Button> */}
                {/* TODO: activate Angebot anfordern in v2 */}
                <Box/>
                <Button leftIcon={<FiRsCreditCard/>} colorScheme="accent" onClick={confirmOrderModal.onOpen}>
                  {formatMessage({ id: '--to-checkout' })}
                </Button>
                <ConfirmOrderModal disclosure={confirmOrderModal} sendBasketToOrder={sendBasketToOrder} basketId={basketId}/>
              </HStack>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
