import { Button, Center, Heading, Text } from '@chakra-ui/react'
import { NextLink } from 'design_system/utility/NextLink'
import { STOCKER_LOGIN_PAGE } from 'helper/environment'
import NextImage from 'next/image'
import type React from 'react'
import { useIntl } from 'react-intl'

export interface ILoginNoticeProps {
  redirect?: string
}

export const LoginNotice: React.FC<ILoginNoticeProps> = ({ redirect }) => {
  const { formatMessage } = useIntl()
  return (
    <Center h="50vh" flexDir="column" gap={6}>
      <NextImage
        src="/img/logo_header.svg"
        width="300px"
        height="150px"
      />
      <Heading>{formatMessage({ id: 'configurator-login--please-login' })}</Heading>
      <Text fontSize="2xl">{formatMessage({ id: 'configurator-login--main-text' })}</Text>
      <NextLink href={`${STOCKER_LOGIN_PAGE}?redirect=${redirect ?? 'https://konfigurator.stocker-kaminsysteme.com/'}`}>
        <Button colorScheme="accent" size="lg">{formatMessage({ id: '--login' })}</Button>
      </NextLink>
    </Center>
  )
}
