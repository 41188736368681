import type { PlacementWithLogical } from '@chakra-ui/react'
import { Box, Button, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import { AdjustOrderLine } from 'custom_components/modules/AdjustOrderLine/AdjustOrderLine'
import type React from 'react'

export interface IAmountSelectProps {
  quantity: number
  selectData: {
    min: number
    max: number
  }
  price?: number
  currencyCode?: string
  onSubmit: (amount: number) => void
  onDelete: () => void
  isDisabled?: boolean
  popoverTrigger?: React.ReactNode
  popoverPlacement?: PlacementWithLogical
}

export const AmountSelect: React.FC<IAmountSelectProps> = ({ quantity, selectData, price, currencyCode, onSubmit, onDelete, popoverTrigger, popoverPlacement, isDisabled = false }) => {
  return (
    <Box w={popoverPlacement ? '' : { base: '30px', xl: '60px' }}>
      <Popover placement={popoverPlacement ?? 'left-start'} isOpen={isDisabled ? false : undefined}>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Box as="span">
                {popoverTrigger && (
                  popoverTrigger
                )}
                {!popoverTrigger && (
                  <Button isDisabled={isDisabled} size="" p={0} variant="outline" w={{ base: '30px', xl: '60px' }} h={{ base: '30px', xl: '60px' }} colorScheme="gray" color="secondaryText.300">{quantity}</Button>
                )}
              </Box>
            </PopoverTrigger>
            <PopoverContent borderRadius="none">
              <PopoverArrow/>
              <PopoverCloseButton/>
              <PopoverBody p={0}>
                <AdjustOrderLine
                  onSubmit={amount => {
                    onSubmit(amount)
                    onClose()
                  }}
                  onDelete={() => {
                    onDelete()
                    onClose()
                  }}
                  price={price}
                  currencyCode={currencyCode}
                  quantity={{ initial: quantity, min: selectData.min, max: selectData.max }}
                />
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  )
}
